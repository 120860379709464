import { RouterProvider } from 'react-router-dom'
import { router } from './router'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { selectAppState, setProgress } from 'redux/slices/appStateSlice'
import FullPageLoader from 'components/FullPageLoader'
import { useCheckRejectEventsEffect } from 'hooks/useCheckRejectEventsEffect'
import { EventTypes } from 'share/types/core'
import { useInitiateLoanMutation } from 'services/api/main-events-api'
import { useGetTaskTrackerStatusQuery } from 'services/api/loan-api'
import { useEffect, useState } from 'react'
import { TaskTrackerTypes } from 'share/types/task-tracker-types'
import { getRouteByTaskTrackerStatus } from 'utils/route-mapper'
import { pathnames } from 'router/pathnames'
import { useAwaitEventRecievedEffect } from 'hooks/useAwaitEventRecievedEffect'
import { getProgressByTaskTrackerType } from 'utils/app-steps'

const GLOBAL_REJECT_EVENTS = {
  events: [EventTypes.loan_refusal]
}

// TODO: isolate app init logic into separate component or hook
// This is the root component where user's last state is restored and navigated to binded route by event
const App = () => {
  // Tools
  const dispatch = useAppDispatch()

  // State
  const [isRouteFound, setIsRouteFound] = useState(false)

  // Selectors
  const { isAppReady, isAppError } = useAppSelector(selectAppState)

  // Queries
  const { data, isSuccess, isLoading, isError } = useGetTaskTrackerStatusQuery(null, {
    skip: !isAppReady
  })

  // Mutations
  const [initLoan, { isLoading: isInitLoanLoading, isError: isInitLoanError }] = useInitiateLoanMutation()

  // Effects
  useEffect(() => {
    const loanStatusChecker = async () => {
      if (!isSuccess) return
      if (!data) {
        setIsRouteFound(true)
        return
      }
      if (data.type === TaskTrackerTypes.MESSAGE_SUPER_APP_APPLICATION_PRE_APPROVE && data.status === 'SUCCESS') {
        await initLoan().unwrap()
        await router.navigate(pathnames.lead)
        setIsRouteFound(true)
        return
      }
      const pathname = window.location.pathname
      const progress = getProgressByTaskTrackerType(data.type)
      const route = getRouteByTaskTrackerStatus(data.type)
      if (
        data.type === TaskTrackerTypes.REDIRECT_BORROWER_TO_VIDEO_IDENTIFICATION &&
        pathname.includes(pathnames['identification-results'])
      ) {
        setIsRouteFound(true)
        return
      }
      dispatch(setProgress(progress))
      await router.navigate(route ? route : '404')
      setIsRouteFound(true)
    }

    loanStatusChecker().catch(console.error)
  }, [data, dispatch, initLoan, isSuccess])

  useCheckRejectEventsEffect(GLOBAL_REJECT_EVENTS)

  useAwaitEventRecievedEffect(EventTypes.need_wait_work_time, () => {
    void router.navigate(pathnames['waiting-page'])
  })

  // Render
  if (isError || isAppError || isInitLoanError) {
    return 'Error happened when initializing app. Please try again later.'
  }

  if (!isAppReady || isLoading || isInitLoanLoading || !isRouteFound) return <FullPageLoader />

  if (!data) return 'This loan was not pre-approved. Please try again later.'

  return <RouterProvider router={router} />
}

export default App
