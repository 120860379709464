import { EventTypes } from 'share/types/core'
import { TaskTrackerTypes } from 'share/types/task-tracker-types'

export const APP_STEPS = [
  {
    progress: 0,
    wsEvents: [EventTypes.borrower_otp_verification_attempts_exceeded, EventTypes.borrower_otp_code_incorrect],
    taskTrackerEvents: [
      TaskTrackerTypes.MESSAGE_SUPER_APP_APPLICATION_PRE_APPROVE,
      TaskTrackerTypes.BORROWER_OTP_VERIFICATION_ATTEMPTS_EXCEEDED,
      TaskTrackerTypes.BORROWER_OTP_CODE_INCORRECT,
      TaskTrackerTypes.BORROWER_INITIATED_LOAN,
      TaskTrackerTypes.BORROWER_CONDITIONS_SELECTED,
      TaskTrackerTypes.BORROWER_ENTERED_PHONE_VERIFICATION_CODE,
      TaskTrackerTypes.BORROWER_REQUESTED_NEW_PHONE_VERIFICATION_CODE
    ]
  },
  {
    progress: 20,
    wsEvents: [
      EventTypes.borrower_otp_code_successful,
      EventTypes.internal_checks_successful,
      EventTypes.all_checks_successful
    ],
    taskTrackerEvents: [
      TaskTrackerTypes.BORROWER_OTP_CODE_SUCCESSFUL,
      TaskTrackerTypes.INTERNAL_CHECKS_SUCCESSFUL,
      TaskTrackerTypes.ALL_CHECKS_SUCCESSFUL,
      TaskTrackerTypes.BORROWER_CHOSE_FREEDOM
    ]
  },
  {
    progress: 40,
    wsEvents: [
      EventTypes.need_take_car_photo,
      EventTypes.necessary_confirm_choosen_conditions,
      EventTypes.borrower_can_confirm_choosen_conditions,
      EventTypes.borrower_cannot_confirm_choosen_conditions,
      EventTypes.pledge_approved,
      EventTypes.borrower_needs_upload_real_estate_documents,
      EventTypes.necessary_provide_spouse_details,
      EventTypes.spouse_not_own_the_number_provided,
      EventTypes.spouse_own_the_number_provided
    ],
    taskTrackerEvents: [
      TaskTrackerTypes.NEED_TAKE_CAR_PHOTO,
      TaskTrackerTypes.NECESSARY_CONFIRM_CHOOSEN_CONDITIONS,
      TaskTrackerTypes.BORROWER_CAN_CONFIRM_CHOOSEN_CONDITIONS,
      TaskTrackerTypes.BORROWER_CANNOT_CONFIRM_CHOOSEN_CONDITIONS,
      TaskTrackerTypes.PLEDGE_APPROVED,
      TaskTrackerTypes.BORROWER_NEEDS_UPLOAD_REAL_ESTATE_DOCUMENTS,
      TaskTrackerTypes.NECESSARY_PROVIDE_SPOUSE_DETAILS,
      TaskTrackerTypes.SPOUSE_NOT_OWN_THE_NUMBER_PROVIDED,
      TaskTrackerTypes.SPOUSE_OWN_THE_NUMBER_PROVIDED,

      TaskTrackerTypes.BORROWER_PROVIDED_CAR_PHOTO,
      TaskTrackerTypes.COLLATERAL_DECISION_RECEIVED,
      TaskTrackerTypes.ESTIMATION_ALL_CLIENT_DOCUMENTS,
      TaskTrackerTypes.ESTIMATION_RESULT_RECEIVED,
      TaskTrackerTypes.BORROWER_AGREED_CONDITIONS_LOAN
    ]
  },
  {
    progress: 60,
    wsEvents: [
      EventTypes.spouse_agreed_loan,
      EventTypes.borrower_necessary_provide_access_digital_documents,
      EventTypes.borrower_digital_documents_code_incorrect,
      EventTypes.borrower_attempts_digital_documents_code_are_over,
      EventTypes.registration_in_banking_system_started,
      EventTypes.registration_in_bank_system_completed,
      EventTypes.documents_generated,
      EventTypes.document_validation_success,
      EventTypes.need_wait_work_time
    ],
    taskTrackerEvents: [
      TaskTrackerTypes.SPOUSE_AGREED_LOAN,
      TaskTrackerTypes.BORROWER_NECESSARY_PROVIDE_ACCESS_DIGITAL_DOCUMENTS,
      TaskTrackerTypes.BORROWER_DIGITAL_DOCUMENTS_CODE_INCORRECT,
      TaskTrackerTypes.BORROWER_ATTEMPTS_DIGITAL_DOCUMENTS_CODE_ARE_OVER,
      TaskTrackerTypes.REGISTRATION_IN_BANKING_SYSTEM_STARTED,
      TaskTrackerTypes.REGISTRATION_IN_BANK_SYSTEM_COMPLETED,
      TaskTrackerTypes.DOCUMENTS_GENERATED,
      TaskTrackerTypes.DOCUMENT_VALIDATION_SUCCESS,
      TaskTrackerTypes.NEED_WAIT_WORK_TIME,

      TaskTrackerTypes.BORROWER_PROVIDED_SPOUSE_PHONE,
      TaskTrackerTypes.BORROWER_IS_NOT_MARRIED,
      TaskTrackerTypes.BORROWER_REPORTED_THAT_UPDATED_MARITAL_STATUS,
      TaskTrackerTypes.BORROWER_ENTERED_DIGITAL_DOCUMENTS_CODE,
      TaskTrackerTypes.BORROWER_REQUESTED_NEW_PROVIDING_DIGITAL_DOCUMENTS_CODE,
      // Spouse events
      TaskTrackerTypes.BORROWER_RESULT_BIOMETRY_RECEIVED,
    ]
  },
  {
    progress: 80,
    wsEvents: [
      EventTypes.borrower_need_initiate_documents_signing_forensics,
      EventTypes.borrower_video_identification_failure_forensics,
      EventTypes.borrower_video_identification_successful_forensics,
      EventTypes.borrower_need_initiate_documents_signing_did_aitu,
      EventTypes.borrower_signature_confirmation_otp_code_successful,
      EventTypes.borrower_signature_confirmation_otp_code_incorrect,
      EventTypes.borrower_signature_confirmation_otp_verification_attempts_exceeded,
      EventTypes.redirect_borrower_to_video_identification,
      EventTypes.borrower_video_identification_failure,
      EventTypes.borrower_video_identification_successful
    ],
    taskTrackerEvents: [
      TaskTrackerTypes.BORROWER_NEED_INITIATE_DOCUMENTS_SIGNING_FORENSICS,
      TaskTrackerTypes.BORROWER_VIDEO_IDENTIFICATION_FAILURE_FORENSICS,
      TaskTrackerTypes.BORROWER_VIDEO_IDENTIFICATION_SUCCESSFUL_FORENSICS,
      TaskTrackerTypes.BORROWER_NEED_INITIATE_DOCUMENTS_SIGNING_DID_AITU,
      TaskTrackerTypes.BORROWER_SIGNATURE_CONFIRMATION_OTP_CODE_SUCCESSFUL,
      TaskTrackerTypes.BORROWER_SIGNATURE_CONFIRMATION_OTP_CODE_INCORRECT,
      TaskTrackerTypes.BORROWER_SIGNATURE_CONFIRMATION_OTP_VERIFICATION_ATTEMPTS_EXCEEDED,
      TaskTrackerTypes.REDIRECT_BORROWER_TO_VIDEO_IDENTIFICATION,
      TaskTrackerTypes.BORROWER_VIDEO_IDENTIFICATION_FAILURE,
      TaskTrackerTypes.BORROWER_VIDEO_IDENTIFICATION_SUCCESSFUL,

      TaskTrackerTypes.BORROWER_INITIATED_DOCUMENTS_SIGNING,
      TaskTrackerTypes.BORROWER_INITIATED_VIDEO_IDENTIFICATION,
      TaskTrackerTypes.BORROWER_DATA_TRANSFERRED_TO_FORENSICS
    ]
  },
  {
    progress: 100,
    wsEvents: [EventTypes.loan_successfully_processed],
    taskTrackerEvents: [TaskTrackerTypes.LOAN_SUCCESSFULLY_PROCESSED]
  }
]

// For rejected events or events that are not in the list return 0
export const getProgressByTaskTrackerType = (status: TaskTrackerTypes) => {
  const step = APP_STEPS.find(step => step.taskTrackerEvents.includes(status))
  return step ? step.progress : 0
}

export const getProgressByWsEvent = (event: EventTypes) => {
  const step = APP_STEPS.find(step => step.wsEvents.includes(event))
  return step ? step.progress : 0
}
