import { useAppDispatch } from 'hooks/useRedux'
import { createContext, type ReactNode, useEffect } from 'react'
import { setAuthState, setIsAuthTokensPresent } from 'redux/slices/appStateSlice'
import { useGenerateTestTokensMutation, useGetCasKeysMutation } from 'services/api/auth-api'
import TokenService from 'services/token.service'
import { MODE } from 'share/constants'
import * as Sentry from '@sentry/browser'

const isAuthJWTsSettled = TokenService.isAuthJWTsSettled()
const AuthContext = createContext(null)

const AuthProvider = ({ children }: { children: ReactNode }) => {
  // Tools
  const dispatch = useAppDispatch()

  // Mutations
  const [fetchCasKeys] = useGetCasKeysMutation()
  const [generateTestTokens] = useGenerateTestTokensMutation()

  // Effects
  useEffect(() => {
    if (isAuthJWTsSettled) return
    let mutation

    if (MODE === 'development') {
      const searchParams = new URLSearchParams(window.location.search)
      const id = searchParams.get('id')
      mutation = generateTestTokens(id)
    } else {
      mutation = fetchCasKeys()
    }

    const initAuth = async () => {
      try {
        await mutation.unwrap()
        dispatch(setAuthState('success'))
        dispatch(setIsAuthTokensPresent(true))
      } catch (err) {
        Sentry.captureMessage('Error happened when fetching casKeys: ', {
          level: 'error',
          extra: {
            error: err
          }
        })
        dispatch(setAuthState('error'))
        dispatch(setIsAuthTokensPresent(false))
      }
    }

    void initAuth()

    return () => {
      mutation.abort()
    }
  }, [dispatch, fetchCasKeys, generateTestTokens])

  return <AuthContext.Provider value={null}>{children}</AuthContext.Provider>
}

export { AuthProvider }
